import React, { Component } from 'react';

class NotFound extends Component {

  render() {
    return (
      <div>
        <h1>
          404 <small>Not Found :(</small>
        </h1>
      </div>
    );
  }
}

export default NotFound;